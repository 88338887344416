$(document).ready(function () {
  //slider mainPageSlider
  $(".mainPageSlider").owlCarousel({
    items: 1,
    lazyLoad: true,
    //autoplayHoverPause: true, 
    loop: true,
    mouseDrag: false,
    margin: 0,
    autoplay: true,
    autoplayTimeout: 4000,
    smartSpeed: 1250,
    nav: false,
    dots: false,
    navText: [$(".owl-left"), $(".owl-right")],
  });
  //end slider mainPageSlider



  //slider photoSlider
  $(".photoSlider").owlCarousel({
    items: 1,
    lazyLoad: true,
    loop: true,
    margin: 10,
    autoplay: true,
    autoplayTimeout: 4000,
    smartSpeed: 1250,
    nav: false,
    dots: true,
    //navText: [$('.owl-left'),$('.owl-right')]
  });
  //end slider photoSlider

  //animacja wejscia dla stron
  //document.body.classList.add('fadeIn');

  //header scroll fixed
  $(window).scroll(function () {
    if (($(window).scrollTop() >= 10)) {
      $(".page-template-front-page header").addClass('headerSimple');

      if (($(window).width() > 1050)) {
        $(".page-template-front-page .reservationPanelWrap").addClass('fixed');
        $(".page-template-front-page .promoInfo").fadeOut();
      }

      // $("header .mainLogoWhite").fadeOut(200);
      // $("header .mainLogoColor").delay(300).fadeIn(200);
    } else {
      $(".page-template-front-page header").removeClass('headerSimple');

      if (($(window).width() > 1050)) {
        $(".page-template-front-page .reservationPanelWrap").removeClass('fixed');
        $(".page-template-front-page .promoInfo").fadeIn();
      }

      // $("header .mainLogoColor").fadeOut(200);
      // $("header .mainLogoWhite").delay(300).fadeIn(200);
    }
  });
  //end header scroll fixed


  //header scroll headerSimple
  // $(window).scroll(function () {
  //   if ($(window).scrollTop() >= 200) {
  //     $(".fixedHeader").addClass("active");
  //   } else {
  //     $(".fixedHeader").removeClass("active");
  //   }
  // });
  //end header scroll headerSimple

  //zmiana wygladu headera gdy nie jest wlaczony slider
  if ($(".mainSlider").length === 0) {
    // $("header").css("background-color", "rgba(0,45,114,.7)");
    // $(".dropdown-menu").css("background-color", "rgba(0,45,114,.7)");
    //$("body").css("padding-top", "100px");
  }
  //

  /// klikalne dropdow
  // $('.dropdown > a').click(function(e){
  //   var url = e.target.getAttribute('href');
  //   window.location.href = url;
  // })
  ///

  //burger menu
  $(".burger").click(function (e) {
    e.stopPropagation();
    $(this).toggleClass("active");
    $(".nav").slideToggle("fast");
  });
  //end burger menu

  //pokaz formularz rozwijany konferencje
  $(".specialForm .showFormPanel img").click(function () {
    $(".formConference .formWrap").slideToggle();
  });
  //end pokaz formularz rozwijany konferencje

  //preloader booking rezerwacja
  // $('#booking_frame').load(function () {
  // 	setTimeout(function() {
  // 		$('#booking_frame').css('background-color', '#fff');
  // 	}, 4000);
  // });

  //slider hotel-slider
  $(".hotel-slider").slick({
    infinite: true,
    speed: 900,
    lazyLoad: "ondemand",
    centerMode: true,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 2000,
  });

  $(".opinions-slider").slick({
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 3000,
    dots: false,
    margin: 25,
    padding: 25,
    // responsive: [
    //     {
    //       breakpoint: 1150,
    //       settings: {
    //         slidesToShow: 3
    //       }
    //     },
    //     {
    //       breakpoint: 992,
    //       settings: {
    //         slidesToShow: 2
    //       }
    //     },
    //     {
    //       breakpoint: 576,
    //       settings: {
    //         slidesToShow: 1
    //       }
    //     }
    //   ]
  });

  //panelMobileBottom
  var lastScrollTop = 0;
  $(window).scroll(function (event) {
    if (window.screen.width <= 768) {
      var st = $(this).scrollTop();
      if (st > lastScrollTop) {
        // downscroll code
        $(".mobileBottomPanel").slideDown();
      } else {
        // upscroll code
        //$('.mobileBottomPanel').stop().slideUp()
      }
    } else {
      $(".mobileBottomPanel").stop().slideUp();
    }
  });

  //pozycja dla kalendarza
  function topCalendar() {
    var offset = $(".reservationPanel").offset().top;
    var heightPanel = $(".reservationPanel").height();
    var calendarHeight = 271;
    //var mainOffset = offset + heightPanel - 200;


    if ($(window).width() < 576) {
      var mainOffset = offset;
    } else {
      if ($(".reservationPanelWrap").hasClass('reservationPanelWrap--slim') || $(".reservationPanelWrap").hasClass('fixed')) {
        var mainOffset = offset - 230;
      } else {
        var mainOffset = offset - 160;
      }

    }

    $("#calroot").css("top", mainOffset);
  }

  topCalendar();

  // openReservationPanel

  $(".openReservationPanel").click(function () {
    $(".reservationPanelWrap").slideToggle().promise().done(topCalendar);
  });

  // end openReservationPanel

  $(window).resize(function () {
    topCalendar();
  });

  $(window).scroll(function () {
    topCalendar();
  });
  // end pozycja dla kalendarza

  //menu boczne

  $(".burgerIcon").click(function (e) {
    e.stopPropagation();
    $(".slideNav").toggleClass("active");
  });



  $(".groupSlider").owlCarousel({
    items: 2,
    lazyLoad: true,
    autoplayHoverPause: true,
    loop: true,
    margin: 10,
    autoplay: true,
    autoplayTimeout: 4000,
    smartSpeed: 1250,
    nav: true,
    dots: false,
    navText: [$('.owl-left'), $('.owl-right')],
    responsive: {
      // breakpoint from 0 up
      0: {
        items: 1,
      },
      // breakpoint from 480 up
      480: {
        items: 1,
      },
      // breakpoint from 768 up
      768: {
        items: 2,
      }
    }
  });

  $(".twoPhotosSlider").owlCarousel({
    items: 2,
    lazyLoad: true,
    autoplayHoverPause: true,
    loop: true,
    margin: 0,
    autoplay: true,
    autoplayTimeout: 4000,
    smartSpeed: 1250,
    nav: true,
    dots: false,
    navText: [$('.owl-left'), $('.owl-right')],
    responsive: {
      // breakpoint from 0 up
      0: {
        items: 1,
      },
      // breakpoint from 480 up
      480: {
        items: 1,
      },
      // breakpoint from 768 up
      768: {
        items: 2,
      }
    }
  });


  $(".logosSlider").owlCarousel({
    items: 2,
    lazyLoad: true,
    loop: true,
    margin: 20,
    autoplay: true,
    autoplayTimeout: 4000,
    smartSpeed: 1250,
    nav: false,
    dots: false,
    //navText: [$('.owl-left'), $('.owl-right')],
    responsive: {
      // breakpoint from 0 up
      0: {
        items: 2,
      },
      // breakpoint from 480 up
      480: {
        items: 2,
        margin: 10
      },
      // breakpoint from 768 up
      768: {
        items: 3,
      },
      992: {
        items: 4,
      },
      1200: {
        items: 5,
      },
      1400: {
        items: 6,
      }
    }
  });

  $(".newsSlider").owlCarousel({
    items: 1,
    lazyLoad: true,
    autoplayHoverPause: true,
    loop: true,
    margin: 10,
    autoplay: true,
    autoplayTimeout: 4000,
    smartSpeed: 1250,
    nav: true,
    dots: false,
    navText: [$('.owl-left'), $('.owl-right')],

  });

  // $('body').click(function (e) {
  //   if ($('.slideNav').hasClass('active')) {
  //     $(".slideNav").toggleClass('active')
  //   }
  // })





  // $(".twoRowsSlider").slick({
  //   rows: 2,
  //   dots: false,
  //   arrows: true,
  //   infinite: true,
  //   speed: 300,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 1200,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //         rows: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 992,
  //       settings: {
  //         slidesToShow: 2,
  //         rows: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         rows: 1,
  //       },
  //     },
  //   ],
  // });

  // --------- scroll effect ----------- //

  // var images = [];
  // //var imagesDirPath = 'http://localhost:3000/desilva/wp-content/themes/hotel/dist/images';
  // var activeItemIndex = 0;
  // var newActiveItemIndex = 0;
  // var $photo = $('.specialScroll .photoWrap .photo');

  // $('[data-photoURL]').each(function () {
  //   images.push($(this).data());
  // })

  // console.log("images:", images);


  // $(window).scroll(function (e) {

  //   $('.specialScroll .item .content').each(function (index) {

  //     var $windowScrollTop = $(window).scrollTop();
  //     var $windowHeight = $(window).height();
  //     var $windowScrollTopDown = $windowScrollTop + $windowHeight;

  //     var $thisElementOffset = $(this).offset().top;

  //     if ($windowScrollTopDown >= $thisElementOffset) {
  //       newActiveItemIndex = index;
  //     }

  //   })

  //   if (newActiveItemIndex != activeItemIndex) {
  //     activeItemIndex = newActiveItemIndex;
  //     $photo.animate({ opacity: 0 }, 'fast', function () {
  //       $(this)
  //         .css({ 'background-image': `url('${images[activeItemIndex].photourl}')` })
  //         .animate({ opacity: 1 }, 'slow');
  //     });
  //   }
  // });

  // --------- ./scroll effect ----------- //

  //vertical slider  
  $('.sliderSidebar').slick({
    dots: false,
    vertical: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    verticalSwiping: true,
  });

  $('.upArrow').click(function () {
    $("html, body").animate({
      scrollTop: 0
    }, 1000);
  })

  $(window).scroll(function () {
    if ($(this).scrollTop() > 50) {
      $('.upArrow').fadeIn();
    } else {
      $('.upArrow').fadeOut();
    }
  });

  if ($(".mainSlider").length === 0) {
    $("header").css("background-color", "#e9e9e9");
    $("header").addClass('headerSimple');
    $('.promoInfo').fadeOut(0);
    $(".page").addClass("noSlider");
    $('main').removeClass('overSlider');
  }
  if (!$('body').hasClass('page-template-front-page')) {
    $("header").css("background-color", "#e9e9e9");
    $("header").addClass('headerSimple');
  }

  $(window).scroll(function () {
    var scrollTop = $(this).scrollTop();
    var blurValue = scrollTop / 100;
    if (blurValue > 60) {
      blurValue = 60;
    }

    $(".mainSlider").css('filter', 'blur(' + blurValue + 'px)');

  });



  $('ul li.dropdown').hover(function () {
    $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeIn(200);
  }, function () {
    $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeOut(200);
  });

  $(".galleryRoomsSlider").slick({
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 3000,
    dots: false,
    margin: 25,
    //padding: 25,
  });

  $(".aboutUs .photoWrap").slick({
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 3000,
    dots: true,
    margin: 25,
    //padding: 25,
  });

  $(".restaurantAndBar .photoWrap").slick({
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 3000,
    dots: true,
    margin: 25,
    //padding: 25,
  });

  $(".sliderAndBox .photoWrap").slick({
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 3000,
    dots: true,
    margin: 25,
    //padding: 25,
  });

  $(".conference .photoWrapSlider").slick({
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 3000,
    dots: true,
    margin: 25,
    //padding: 25,
  });


  $(".specialBanersSlider").slick({
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 3000,
    dots: true,
    margin: 25,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false
        }
      }

    ]


  });


  AOS.init({
    once: true,
    //disable: mobile,
    duration: 1000
  });

});
